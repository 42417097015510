import './../../style/schemaForm.css'

import React, {Component} from 'react';
import Form from 'react-jsonschema-form';
import Wampy from 'wampy';
import superagent from 'superagent';
import styled from '@emotion/styled'
import {toast} from 'react-toastify';

import Loader from './../../components/Loader'
import Text from './../../components/Text'

const Page = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flex: 1,
  color: "whitesmoke"
})

const lockable = ({locked}) => ({
  pointerEvents: locked ? 'none' : 'all',
  opacity: locked ? 0.5 : 1
})
const Container = styled('div')(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: '0.5rem',
      padding: "50px",
      marginTop: "20px",
    },
    lockable
)

class Schema extends Component {
  constructor(props) {
    const url = new URL(window.location.href)
    super(props);
    this.state = {
      locked: false,
      data: null,
      ws: new Wampy('wss://wamp.ucms.site/ws/', {realm: 'interactive'}),
      section: url.searchParams.get("section"),
      instance: url.searchParams.get("instance"),
      error: [],
      succ: [],
      sentList: [],
      uiSchema: {},
      jschema: {}
    }
  }

  componentDidMount() {
    this.getEvents()
  }

  createUUID = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, a => ((a ^ (Math.random() * 16)) >> a / 4).toString(16))

  getEvents = () => {
    const {instance, section, ws} = this.state
    superagent
        .get(`https://${instance}.ucms.site/api/scheduler/config?section=${section}`)
        // .get(`http://${instance}.127.0.0.1.xip.io:8000/api/scheduler/config?section=${section}`)
        .set({Authorization: this.props.token})
        .end((err, res) => {
          if (res.status === 200) {
            if (res.body.events.length > 0)
              if (res.body.events[0].json_schema != null)
                this.setState({data: res.body, uiSchema: res.body})
              else
                res.body.events = [res.body.settings]
            else
              res.body.events = [res.body.settings]


            this.setState({data: res.body, uiSchema: res.body})
            // console.log(res.body)
            res.body.clients.map(e => {
              let topic = e.humanhash.split("-").join(".")

              return ws.subscribe(topic, {
                onSuccess: () =>
                    toast.info("Subscribed to WAMP connection",
                        {toastId: 'wamp-sub', autoClose: 3000}),
                onError: err =>
                    this.setState({error: err},
                        () => toast.error(err)),
                onEvent: res =>
                    this.handleResponse(res)
              })
            })
          } else
            this.setState({error: res.body}, () => toast.error('Error: ' + res.status))
        })
  }

  handleResponse = res => {
    this.setState({locked: false})
    const {sentList} = this.state
    sentList.map(e => {
      if (e.id === res.argsDict.id) {
        e.ack = true
        return toast.success(`${e.name} did respond`, {autoClose: 5000})
      } else
        return toast.error(`${e.name} did not respond`, {autoClose: 5000})
    })
  }

  schemaSubmit = e => {
    this.setState({locked: true, succ: [], error: [], sentList: []})
    const {data, ws, instance, section} = this.state

    data.clients.map(client => {
      let uuid = this.createUUID()
      let topic = client.humanhash.split("-").join(".")

      ws.publish(topic, {"event": e.schema.title, "params": e.formData, "id": uuid})
      this.setState(prevState => ({sentList: [...prevState.sentList, {id: uuid, ack: false, name: client.name}]}),
          () => toast.info(`Published message to ${client.name}, awaiting response...`, {
            autoClose: 6000,
            toastId: client.name
          }))
    })

    superagent
        .post(`https://${instance}.ucms.site/api/scheduler/settings`)
        .set({Authorization: this.props.token})
        .send({section, settings:e.formData})
        .end((err, res)=>{
          console.log(err)
          console.log(res)
        })

    setTimeout(() => this.state.locked ? this.setState({
      locked: false,
      error: "Couldn't connect to any client. Connection timeout."
    }, () => toast.error(this.state.error)) : '', 8000)

  }

  render() {
    const {data, locked, uiSchema} = this.state
    return data ?
        (
            <Page>
              <Container locked={locked}>
                <div>
                  <Form
                      noValidate
                      className="jsonSchemaForm"
                      uiSchema={uiSchema.settings.json_schema}
                      onSubmit={this.schemaSubmit}
                      schema={uiSchema.settings.json_schema}/>
                  {locked ? <Loader/> : ""}
                </div>
              </Container>
              <img style={{alignSelf: "center", marginTop: "5px", maxWidth: "200px", maxHeight: "200px"}}
                   src="Advent_Logo_Grey.png" alt="advent-logo"/>
            </Page>
        ) : (<Page><Text weight={1000} size={2}>loading...</Text></Page>)
  }
}

export default Schema;
