import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from '../Loader'

import colors from 'colors.css'

const Button = styled('div')(
  {
    borderRadius: '1rem',
    textAlign: 'center',
    fontWeight: 700,
    lineHeight: '1rem',
    transition: 'all 0.3s ease-in-out',
    userSelect: 'none'
  },
  ({
    disabled = false,
    locked = false,
    secondary = false,
    compact = false,
    color = colors.green
  }) => ({
    padding: compact ? 0 : '1rem 2rem',
    cursor: disabled || locked ? 'normal' : 'pointer',
    backgroundColor: secondary ? 'transparent' : color,
    color: secondary ? color : 'white',
    opacity: disabled ? 0.25 : 1,
    pointerEvents: disabled || locked ? 'none' : 'all',
  }),
  ({ margin }) => {
    if (Number.isInteger(margin)) {
      return {
        margin: `${margin}rem`
      }
    } else if (Array.isArray(margin)) {
      return {
        margin: margin.map(v => (Number.isInteger(v) ? `${v}rem` : v)).join(' ')
      }
    } else {
      return { margin }
    }
  }
)

export default ({ children, locked, compact = false, disabled, ...props }) => (
  <Button locked={locked} disabled={disabled} compact={compact} {...props}>
    {locked ? <Spinner /> : children}
  </Button>
)
