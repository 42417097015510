import React from 'react'
import styled from '@emotion/styled'

import { Font_Default } from '../../style'

const Input = styled('input')(({ compact }) => ({
  padding: '1rem 1.5rem',
  fontFamily: Font_Default,
  fontSize: '1.25rem',
  lineHeight: '1rem',
  height: '3rem',
  alignSelf: 'stretch',
  border: 'none',
  backgroundColor: `${'rgba(0,0,0,0.25)'} !important`,
  color: '#ffffff',
  borderRadius: '1rem',
  marginBottom: compact ? 0 : '1rem',
  outline: 'none',
  flex: 1,
  '&:focus::placeholder': {
    color: 'transparent'
  }
}))

export default class extends React.PureComponent {
  render() {
    return <Input {...this.props} />
  }
}
