import React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const Container = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(255,255,255,0.5)',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const anim = keyframes({
  '0%': { transform: 'scale(0)' },
  '50%': { transform: 'scale(1)' },
  '100%': { transform: 'scale(0)' }
})

const t = 0.3

const Dot = styled('div')({
  backgroundColor: 'silver',
  width: '0.75rem',
  height: '0.75rem',
  transform: 'scale(0)',
  margin: '0.125rem',
  borderRadius: '50%',
  animation: `${anim} ${t * 3}s infinite ease-in-out`,
  '&:nth-child(2)': {
    animationDelay: `${t / 2}s`
  },
  '&:nth-child(3)': {
    animationDelay: `${t}s`
  }
})

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const Spinner = () => (
  <Row>
    <Dot />
    <Dot />
    <Dot />
  </Row>
)

export default () => (
  <Container>
    <Spinner />
  </Container>
)
