import React, {Component} from 'react'
import superagent from 'superagent'
import v8n from 'v8n'
import styled from '@emotion/styled'
import {toast} from 'react-toastify'

import Input from '../../components/Input'
import Button from '../../components/Button'
import Text from '../../components/Text'


const lockable = ({locked}) => ({
  pointerEvents: locked ? 'none' : 'all',
  opacity: locked ? 0.5 : 1
})

const Page = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
})

const Form = styled('form')(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '300px',
      alignSelf: 'center',
      borderRadius: '0.5rem',
      padding: "5px"
    },
    lockable
)

class Login extends Component {
  state = {
    username: '',
    password: '',
    locked: false,
    error: null
  }

  componentDidMount() {
    const url = new URL(window.location.href)
    if (!url.searchParams.get("instance")) {
      this.setState({error: "Please specify INSTANCE in url.", locked: true})
    }
    if (!url.searchParams.get("section")) {
      this.setState({error: "Please specify SECTION in url.", locked: true})
    }
  }


  onChangeUsername = e =>
      this.setState({username: e.target.value, error: null})
  onChangePassword = e =>
      this.setState({password: e.target.value, error: null})
  onSubmit = e => {
    this.setState({locked: true})
    const {username, password} = this.state
    superagent
        .post("https://central.ucms.site/api/jwt/obtain-token")
        // .post("http://central.127.0.0.1.xip.io:8000/api/jwt/obtain-token")
        .send({username: username, password: password})
        .end((err, res) => {
          if (res.status === 200) {
            toast.success('Welcome ' + username)
            this.props.onLogin(`JWT ${res.body.token}`)
          } else {
            toast.error(Object.values(res.body)[0][0])
            this.setState({error: res.body, locked: false})
          }
        })
  }

  onKeyPress = e => e.key === 'Enter' && this.onSubmit()

  render() {
    const {username, password, locked} = this.state
    const invalid =
        v8n()
            .empty()
            .test(username) ||
        v8n()
            .empty()
            .test(password)
    return (
        <Page>
          <Form locked={locked} autoComplete="on" id="login-form">
            <Text size={2} color={"#bad369"} onClick={() => toast('asd')}>
              Trigger App
            </Text>
            <Input
                type="text"
                className="username-input"
                placeholder="Username"
                value={username}
                onKeyPress={this.onKeyPress}
                onChange={this.onChangeUsername}
            />
            <Input
                type="password"
                placeholder="Password"
                value={password}
                onKeyPress={this.onKeyPress}
                onChange={this.onChangePassword}
            />
            <Button
                onClick={this.onSubmit}
                disabled={invalid}
                locked={locked}
                color={"#bad369"}>
              Login
            </Button>
            <img alt='advent-logo' style={{marginTop: "5px"}} src="Advent_Logo_Grey.png"/>
          </Form>
        </Page>
    )
  }
}

export default Login
