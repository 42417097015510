import styled from '@emotion/styled'

const Text = styled('div')(({ size = 1, weight = 300, color = null }) => ({
  fontSize: `${size}rem`,
  lineHeight: `${size}rem`,
  marginBottom: `${size}rem`,
  fontWeight: weight,
  color
}))

export default Text
