import { injectGlobal } from '@emotion/css'

export const Font_Default = 'sans-serif'

export default () => {
  injectGlobal({
    '*': { boxSizing: 'border-box' },
    html: {
      height: '100vh',
      width:'100vw',
      fontSize: '14px',
      lineHeight: '1rem',
      '@media all and (max-width:1536px)': {
        fontSize: '12px'
      }
    },
    body: {
      minHeight: '100%',
      margin: 0,
      padding: 0,
      fontFamily: Font_Default
    }
  })
}
