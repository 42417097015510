import React, {Component} from 'react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import Login from './../Login';
import Schema from './../SchemaForm';
import styled from "@emotion/styled";

const Page = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: '100vh',
  flex: 1,
})

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null
    }
  }

  render() {
    return (
        <Page>
          <ToastContainer/>
          {this.state.token ?
              <Schema token={this.state.token}/> :
              <Login onLogin={val => this.setState({token: val})}/>}
        </Page>
    )
  }
}

export default App;
